import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Footer = () =>{
    
    const navigate = useNavigate();
    
    return(
        <FooterDiv>
            <ReportProblemDiv>
                <CopyrightText>&copy; Copyright 2024 AIKait.com. All rights reserved.</CopyrightText>
                <ButtonsDiv>
                    <ReportProblemText href="mailto:aikaithelp@gmail.com">Report a Problem</ReportProblemText>
                    <PrivacyPolicyDiv>
                        <PrivacyPolicyButton onClick={() => {navigate('/privacy')}}>Privacy Policy</PrivacyPolicyButton>
                    </PrivacyPolicyDiv>
                </ButtonsDiv>
            </ReportProblemDiv>
        </FooterDiv>
    )
}

//Report a Problem
const FooterDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 20%;
    margin-top: 10%;
`

const CopyrightText = styled.p`
    color: white;
    font-size: 32px;
`

const ButtonsDiv = styled.div`
    display: flex;
    margin: 5% 25%;
`

const ReportProblemDiv = styled.div`
    margin-top: auto;
`

const ReportProblemText = styled.p`
    color: white;
    margin-bottom: auto;
    font-size: 20px;
    text-decoration: underline;
`

const PrivacyPolicyDiv = styled.div`
    margin-left: auto;
    margin-right: 10%;
`

const PrivacyPolicyButton = styled.button`
    background: none;
    color: white;
    margin-bottom: auto;
    font-size: 20px;
    text-decoration: underline; 
    font-family: 'Nerko One', sans-serif;
`

export default Footer