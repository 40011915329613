import styled, {createGlobalStyle} from 'styled-components'

import KaitAIwebsiteGIF from '../assets/KaitGif.gif'
import Footer from './Footer';

const Main = () =>{

    return(
    <MainDiv>
        <GlobalStyle />
        <ContentText>Kait is a Google Chrome extension that utlizes AI to complete tasks on webpages you visit. Simply highlight some text, open the extension, and choose what to do with the text. It's that simple!</ContentText>
        
        <GoToStoreDiv>
            <GoToStoreButton href="https://chromewebstore.google.com/detail/Kait%20AI/olkbjffpdhkpnjodmpampgkofaifbbhl?hl=en&authuser=0">
                <GoToStoreText>Download Kait</GoToStoreText>
            </GoToStoreButton>
        </GoToStoreDiv>
        
        <ExampleDiv>
            <ExampleText>For example: Don't feel like reading a long article to get the gist of it? Simply highlight the whole article, then ask Kait to  summarize it for you!</ExampleText>
            <HowToGif src={KaitAIwebsiteGIF} alt="How To GIF"/>
        </ExampleDiv>

        <GoToStoreDiv>
            <GoToStoreButton href="https://chromewebstore.google.com/detail/Kait%20AI/olkbjffpdhkpnjodmpampgkofaifbbhl?hl=en&authuser=0">
                <GoToStoreText>Download Kait</GoToStoreText>
            </GoToStoreButton>
        </GoToStoreDiv>

        <Footer />
    </MainDiv>
    )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
  }
`;

const MainDiv = styled.div`
    padding: 0;
    margin: 0;
`

const ContentText = styled.p`
    color: white;
    font-size: 42px;
    padding: 10px;
    text-align: center;
    margin-top: 10%;
`



// GO TO STORE DIV

const GoToStoreDiv = styled.div`
    margin: auto;
    margin-top: 5%;
`

const GoToStoreButton = styled.a`
    margin: auto;
    margin-bottom: auto;
    text-align: center;
    display: block;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    width: 60%;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
    text-decoration: none;
`

const GoToStoreText = styled.p`
    text-align: center;
    font-size: 53px;
    margin: 5%;
`





const ExampleDiv = styled.div`
    margin-top: 10%;
    text-align: center;
`

const ExampleText = styled.p`
    color: white;
    font-size: 32px;
`

const HowToGif = styled.img`
    margin-top: 5%;
    width: 100%;  
    height: 60%;
`;

export default Main